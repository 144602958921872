<template>
    <div>
        <p>{{ label }}</p>
        <p class="mb-0 pb-0" v-if="line1">{{line1}}</p>
        <p class="mb-0 pb-0" v-if="line2">{{line2}}</p>
        <p class="mb-0 pb-0" v-if="line3">{{line3}}</p>
        <p class="mb-0 pb-0" v-if="line4">{{line4}}</p>
        <p class="mb-0 pb-0" v-if="line5">{{line5}}</p>
        <p class="mb-0 pb-0" v-if="city">{{city}}</p>
        <!-- TODO: the order of code and state maybe should depend on the choice of country... also selections for cities and states depends on country... so maybe prompt for country first right after the label? -->
        <p class="mb-0 pb-0" v-if="state">{{state}}</p>
        <p class="mb-0 pb-0" v-if="code">{{code}}</p>
        <p class="mb-0 pb-0" v-if="country">{{country}}</p>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        label() {
            return this.attr.label;
        },
        line1() {
            return this.attr.line1;
        },
        line2() {
            return this.attr.line2;
        },
        line3() {
            return this.attr.line3;
        },
        line4() {
            return this.attr.line4;
        },
        line5() {
            return this.attr.line5;
        },
        city() {
            return this.attr.city;
        },
        state() {
            return this.attr.state;
        },
        code() {
            return this.attr.code;
        },
        country() {
            return this.attr.country;
        },
    },

};
</script>
